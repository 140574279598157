import React from "react"
import styled from "@emotion/styled"
import { connect } from "react-redux"

import {
  Layout,
  Title,
  PrimaryButton,
  Input,
  Textarea,
  SEO,
} from "../components"
import dropALine from "../../images/fmessage-sent.svg"
import { mq } from "../utils/media-query"
import { getCurrentPath } from "../redux/actions/pathAction"

const Contact = ({ location, getCurrentPath }) => {
  React.useEffect(() => {
    getCurrentPath(location.pathname)
  })

  return (
    <Layout>
      <SEO title="Contact Us" />
      <StyledSection>
        <Title
          title="we are at your service"
          fontColor="var(--clr-primary-300)"
        />
        <article className="text-wrap">
          <p className="contact-text">
            Drop us a line and we'll get back to you.
          </p>
        </article>
        <div className="section-center">
          <div className="image-wrap">
            <img src={dropALine} alt="drop-a-line" />
          </div>

          <article className="contact-form">
            <form>
              <div className="form-group">
                <Input type="text" name="name" placeholder="name" />
                <Input type="email" placeholder="email" name="email" />
                <Textarea
                  name="message"
                  rows="5"
                  placeholder="type your message here ..."
                ></Textarea>
              </div>
              <PrimaryButton type="submit" className="submit-btn">
                send
              </PrimaryButton>
            </form>
          </article>
        </div>
      </StyledSection>
    </Layout>
  )
}

const StyledSection = styled.section`
  padding: 5rem 0;

  .section-center {
    width: 90vw;
    margin: 0 auto;
    max-width: 1170px;
    display: grid;
    ${mq[1]} {
      grid-template-columns: 1fr 1fr;
      column-gap: 1rem;
      align-items: center;
    }
  }

  .contact-form {
    background: var(--clr-neutral-100);
    border-radius: var(--border-radius-md);
    text-align: center;
    box-shadow: var(--solid-shadow-primary300);
    transition: var(--transition);
    width: 90%;
    max-width: 35rem;
    border: 2px solid rgba(9, 31, 82, 1);
    margin: 0 auto;
  }

  .form-group {
    padding: 1.5rem 1.5rem;
  }
  .form-control {
    display: block;
    width: 100%;
    padding: 0.75rem 1rem;
    margin-bottom: 1.25rem;
    border: 2px solid var(--clr-primary-300);
    border-radius: var(--border-radius);
    text-transform: capitalize;
    letter-spacing: var(--letter-spacing-sm);
    font-family: var(--ff-tertiary);
  }
  .form-control::placeholder {
    color: var(--clr-neutral-300);
    text-transform: capitalize;
    letter-spacing: var(--letter-spacing-sm);
  }

  .submit-btn {
    display: block;
    padding: 1rem 2rem;
    margin: 0 auto 2rem;
    text-transform: capitalize;
  }

  .text-wrap {
    text-align: center;
    font-family: var(--ff-quaternary);
    font-size: 1rem;
    margin-bottom: 3rem;
    color: var(--clr-primary-300);
  }

  .image-wrap {
    display: none;

    ${mq[1]} {
      display: block;
      width: 90%;
      justify-self: center;
    }
  }
`

export default connect(null, { getCurrentPath })(Contact)
